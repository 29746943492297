import {
	AccountInfo,
	EventType,
	InteractionRequiredAuthError,
	PublicClientApplication,
	RedirectRequest,
} from '@azure/msal-browser'
import {
	LOCAL_STORAGE_EMT_DOCUMENT_GLOBAL_ATTRIBUTE_FILTER,
	LOCAL_STORAGE_MODULES,
	LOCAL_STORAGE_USER_TOKEN_KEY,
} from 'src/appConfig/contans'
import { serverUrl } from 'src/services/constants'
import browserHistory from 'src/services/history'
import { getAppUrl } from 'src/utils/getBaseUrl'
import { env, getAimsMsalConfig } from 'src/utils/msalConfig'
import { storageService } from 'src/utils/storageService'
import { HAS_CHECKED_DEFAULT_MODULE_STORAGE_KEY } from 'src/utils/useDefaultModuleRedirect'

export const REFRESH_TOKEN_ATTEMPTED_STORAGE_KEY = 'aims/refreshTokenAttempted'

export const msalInstance = (() => {
	let mi: PublicClientApplication = new PublicClientApplication(getAimsMsalConfig())

	const getAuthRequestAims = () => ({
		scopes: [
			'openid',
			'profile',
			'offline_access',
			`https://${env.VITE_MSAL_TENANT_NAME}.onmicrosoft.com/Aims/ReadWrite.All`,
		],
		state: getAppUrl(),
	})

	const instance = {
		async initialize(policy?: string): Promise<void> {
			if (policy) {
				mi = new PublicClientApplication(getAimsMsalConfig(policy))
			}
			await mi.initialize()
			checkActiveAccounts(mi)
		},
		get instance() {
			return mi
		},
		get authRequest(): RedirectRequest {
			return {
				...getAuthRequestAims(),
			}
		},
		async getAimsToken() {
			const account = mi.getActiveAccount()
			if (account) {
				let token: string | null = null
				const request = { ...getAuthRequestAims(), account }
				try {
					const data = await mi.acquireTokenSilent(request)
					token = data.accessToken
				} catch (error) {
					if (error instanceof InteractionRequiredAuthError) {
						await mi.acquireTokenRedirect(request)
					}
				}
				if (token) {
					setAuthDataToLocalStorage(token)
				}
				return token
			}
			return null
		},
		async refresh(): Promise<string | null> {
			const account = mi.getActiveAccount()
			if (!account) {
				return null
			}

			const silentRequest = {
				...getAuthRequestAims(),
				account,
			}

			try {
				const { accessToken } = await mi.ssoSilent(silentRequest)
				return accessToken
			} catch (error) {
				if (error instanceof InteractionRequiredAuthError) {
					sessionStorage.setItem(REFRESH_TOKEN_ATTEMPTED_STORAGE_KEY, 'true')
					await mi.acquireTokenRedirect(silentRequest)
				}
			}
			return null
		},
		// only to handle refresh for PHP app
		async refreshFromRefreshPage(redirectUrl: string) {
			let url: string = serverUrl
			url = url.replace('http://', '')
			url = url.replace('https://', '')
			const isRedirectOnTheSameDomain = redirectUrl.indexOf(url) !== -1 || redirectUrl.indexOf('localhost') !== -1
			if (!mi.getAllAccounts() && mi.getAllAccounts() === null) {
				if (isRedirectOnTheSameDomain) {
					removeDataFromLocalStorage()
					storageService.setLastVisitedPathname(redirectUrl)
					browserHistory.push('/')
					return
				}
			}
			const silentRequest = {
				...getAuthRequestAims(),
				account: mi.getAllAccounts()[0],
			}

			if (!silentRequest.account) {
				removeDataFromLocalStorage()
				if (isRedirectOnTheSameDomain) {
					storageService.setLastVisitedPathname(redirectUrl)
					return
				}
				await this.logout()
			}

			try {
				const { idToken, accessToken } = await mi.acquireTokenSilent(silentRequest)
				if (!accessToken || !idToken) {
					throw new Error('Acquire silent token request failed.')
				}
				setAuthDataToLocalStorage(accessToken)
				if (isRedirectOnTheSameDomain) {
					if (redirectUrl.indexOf('localhost') !== -1) {
						window.location.replace(`http://${redirectUrl}?userToken=${idToken}`)
					} else {
						window.location.replace(
							`${window.location.hostname === 'localhost' ? 'http://' : 'https://'}${redirectUrl}/`,
						)
					}
					return
				} else {
					browserHistory.replace('/')
					if (isRedirectOnTheSameDomain) {
						storageService.setLastVisitedPathname(redirectUrl)
						return
					}
				}
				return
			} catch (error) {
				removeDataFromLocalStorage()
				await this.logout()
			}
		},
		async logout() {
			removeDataFromLocalStorage()
			const accounts = mi.getAllAccounts() ?? []
			localStorage.removeItem('contract')
			const account =
				accounts.find((acc) => acc.idTokenClaims?.aud === process.env.REACT_APP_MSAL_CLIENT_ID!) ||
				mi.getAllAccounts()[0]
			if (account) {
				await mi.logoutRedirect({ account, state: getAppUrl() })
			} else {
				window.location.replace('/')
			}
		},
	}

	return instance
})()

function checkActiveAccounts(msalInstance: PublicClientApplication) {
	// Default to using the first account if no account is active on page load
	if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
		// Account selection logic is app dependent. Adjust as needed for different use cases.
		const account = msalInstance.getAllAccounts()[0]
		clearAllOtherAccounts(account)
		msalInstance.setActiveAccount(account)
	}

	// Optional - This will update account state if a user signs in from another tab or window
	msalInstance.enableAccountStorageEvents()

	msalInstance.addEventCallback((event: any) => {
		if (
			(event.eventType === EventType.LOGIN_SUCCESS ||
				event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
				event.eventType === EventType.SSO_SILENT_SUCCESS) &&
			event?.payload?.account
		) {
			const { account } = event.payload
			clearAllOtherAccounts(account)
			msalInstance.setActiveAccount(account)
		}
		if (event.eventType === EventType.ACCOUNT_REMOVED) {
			window.location.replace('/')
		}
	})

	function clearAllOtherAccounts(account: AccountInfo) {
		msalInstance
			.getAllAccounts()
			.filter((acc) => acc.localAccountId !== account.localAccountId)
			.map((acc) => {
				msalInstance.clearCache({ account: acc })
			})
	}
}

function removeDataFromLocalStorage() {
	localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN_KEY)
	localStorage.removeItem(LOCAL_STORAGE_MODULES)
	localStorage.removeItem(HAS_CHECKED_DEFAULT_MODULE_STORAGE_KEY)
	localStorage.removeItem(LOCAL_STORAGE_EMT_DOCUMENT_GLOBAL_ATTRIBUTE_FILTER)
	sessionStorage.removeItem(REFRESH_TOKEN_ATTEMPTED_STORAGE_KEY)
	document.cookie = 'userToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC'
	generateDomainVariations(location.host).forEach((domain) => {
		document.cookie = `userToken=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 UTC`
	})
}

const setAuthDataToLocalStorage = (token: string) => {
	localStorage.setItem(LOCAL_STORAGE_USER_TOKEN_KEY, JSON.stringify(token))
	document.cookie = `userToken=${JSON.stringify(token)};path=/`
}

function generateDomainVariations(domain: string): string[] {
	const parts = domain.split('.')

	if (parts.length < 2) {
		throw new Error('Invalid domain format')
	}

	const mainDomain = parts.slice(-2).join('.')

	if (parts.length === 2) {
		return [`.${mainDomain}`, mainDomain]
	}

	const subVariations = generateDomainVariations(parts.slice(1).join('.'))

	return [`.${domain}`, domain, ...subVariations]
}
